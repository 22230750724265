import React from "react";
import { Container, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { grey, blueGrey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: "auto",
    backgroundColor: "rgba(255,255,255,0.5)",
    position: "relative",
    zIndex: 2,
    color: grey[500],
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
      justifyContent: "center",
    },
  },
  footerLink: {
    margin: theme.spacing(1, 0),
    color: "white",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
    },
  },
  phoneIcon: {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24,
  },
  title: {
    opacity: 0.7,
    marginBottom: theme.spacing(2),
  },
  menuItem: {
    height: 30,
    minHeight: 30,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textTransform: "none",
  },
  logo: {
    height: 60,
    width: "auto",
    margin: theme.spacing(0, 2),
  },
}));

const Footer = () => {
  const classes = useStyles();

  const footerMenu = (
    <>
      {[
        {
          title: "Impressum",
          url: "https://www.zak-kl.de/impressum",
        },
        {
          title: "Datenschutzerklärung",
          url: "https://www.zak-kl.de/datenschutzerklaerung",
        },
      ].map((item, index) => (
        <Button
          size="small"
          className={clsx(classes.menuItem)}
          key={index}
          component="a"
          href={item.url}
          target="_blank"
        >
          {item.title}
        </Button>
      ))}
    </>
  );

  return (
    <>
      <footer className={classes.footer}>
        <Box py={2}>
          <Container maxWidth="lg">
            <Box>
              <Box>{footerMenu}</Box>
            </Box>
          </Container>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
