import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { grey } from "@material-ui/core/colors";

export const THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        light: "#ffcd8b",
        main: "#f08c01",
        dark: "#d16e03",
      },
      secondary: {
        light: "#e7f4d9",
        main: "#73b025",
        dark: "#5e9726",
      },
      background: {
        paper: "#fff",
        default: "#f1f1f1",
      },
    },
    typography: {
      fontFamily: "Roboto",
      h1: {
        fontWeight: 300,
        fontSize: "2.5rem",
      },
      h2: {
        fontWeight: 700,
        fontSize: "2.4rem",
      },
      h3: {
        fontWeight: 700,
        fontSize: "1.6rem",
      },
      h4: {
        fontWeight: 700,
        fontSize: "1.6rem",
      },
      h5: {
        fontSize: "1.1rem",
        fontWeight: 700,
      },
      overline: {
        letterSpacing: 1,
      },
      caption: {
        fontWeight: 400,
        fontSize: "0.8rem",
        color: grey[600],
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.3,
      },
    },
    shape: {
      borderRadius: 4,
    },
    status: {
      danger: "orange",
    },
    overrides: {
      /*
      MuiPopover: {
        paper: {
          overflow: "visible !important",
          marginLeft: 10,
          "&:before": {
            content: '""',
            display: "block",
            width: "0",
            height: "0",
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderBottom: "10px solid #fff",
            right: 20,
            top: -10
          }
        }
      }
      */
    },
    props: {
      MuiButton: {
        disableFocusRipple: true,
      },
      MuiTab: {
        disableFocusRipple: true,
      },
      MuiIconButton: {
        disableFocusRipple: true,
      },
    },
  })
);
