import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Paper,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as agreementActions from "../store/actions/agreementActions";
import useScreensize from "../hooks/useScreensize";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "15px solid #eaeaea",
    background: "#fff",
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  link: {
    color: "#000 !important",
  },
}));

const Agreement = () => {
  const dispatch = useDispatch();
  const agreement = useSelector((state) => state.agreement);
  const classes = useStyles();
  const { xs } = useScreensize();

  const toggleAgreement = () => {
    if (agreement) {
      return dispatch(agreementActions.disable());
    }
    dispatch(agreementActions.enable());
  };

  const contentReduced = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={xs ? 1 : 0}
    >
      <FormControlLabel
        control={
          <Switch
            checked={agreement}
            onChange={toggleAgreement}
            name="agreement"
            color="secondary"
          />
        }
        label="Externe Inhalte"
      />
      <a
        href="https://www.zak-kl.de/datenschutzerklaerung"
        target="_blank"
        className={classes.link}
      >
        Datenschutzerklärung
      </a>
    </Box>
  );

  const contentLarge = (
    <>
      <Paper className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Hinweis
        </Typography>
        <Typography variant="body1" className={classes.text}>
          An dieser Stelle finden Sie externen Inhalt. Sie können sich hier alle
          externen Inhalte mit einem Klick anzeigen lassen oder wieder
          ausblenden.
        </Typography>
        <Box py={5}>
          <FormControlLabel
            control={
              <Switch
                checked={agreement}
                onChange={toggleAgreement}
                name="agreement"
                color="primary"
              />
            }
            label={
              <strong>{`Externe Inhalte ${
                agreement ? "ausblenden" : "anzeigen"
              }`}</strong>
            }
          />
        </Box>
        <Typography variant="caption" className={classes.hint}>
          Ich bin damit einverstanden, dass mir externe Inhalte angezeigt
          werden. Damit können personenbezogene Daten an Drittplattformen
          übermittelt werden.{" "}
          <a
            href="https://www.zak-kl.de/datenschutzerklaerung"
            target="_blank"
            className={classes.link}
          >
            Weitere Informationen in unserer Datenschutzerklärung
          </a>
        </Typography>
      </Paper>
    </>
  );

  return agreement ? contentReduced : contentLarge;
};

export default Agreement;
