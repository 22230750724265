import API from "../../utils/API";

export const getData = () => async (dispatch, getState) => {
  API.get("videos?_limit=1000&_sort=publishedAt:DESC")
    .then((res) => {
      const videosResult = res.data ? res.data : [];
      const videos = videosResult.reduce((obj, cur) => {
        obj[cur.videoId] = cur;
        return obj;
      }, {});
      dispatch({
        type: "SET_VIDEOS",
        videos: videos,
      });
    })
    .catch((error) => {});
};

export const getTagData = () => async (dispatch, getState) => {
  API.get("tags")
    .then((res) => {
      const tags = res.data ? res.data : [];
      dispatch({
        type: "SET_TAGS",
        tags: ["Alle", ...tags.map((m) => m.title).slice(0, 10)],
      });
    })
    .catch((error) => {});
};

export const setActiveVideo = (video) => (dispatch, getState) => {
  const lastActiveVideo = getState().video.activeVideo;
  dispatch({
    type: "SET_ACTIVE_VIDEO",
    activeVideo: video,
    lastActiveVideo,
  });
};

export const setActiveTags = (activeTags) => (dispatch, getState) => {
  dispatch({
    type: "SET_ACTIVE_TAGS",
    activeTags,
  });
};
