import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Box,
  Container,
  makeStyles,
  Grid,
  Paper,
  Grow,
  Fade,
  withStyles,
} from "@material-ui/core";
import API from "../../utils/API";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { blueGrey } from "@material-ui/core/colors";
import VideoDetail from "../VideoDetail";
import VideoPreview from "../VideoPreview";
import { useDispatch, useSelector } from "react-redux";
import * as videoActions from "../../store/actions/videoActions";
import Pagination from "@material-ui/lab/Pagination";
import * as Scroll from "react-scroll";
import useScreensize from "../../hooks/useScreensize";
import clsx from "clsx";
import useWindowSize from "../../hooks/useWindowSize";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.secondary.main,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  selectedToggleButton: {
    background: `${theme.palette.secondary.main} !important`,
    color: "#fff !important",
  },
  paper: {
    borderRadius: 0,
    cursor: "pointer",
  },
  hidden: {
    display: "none",
  },
  detailWrapper: {
    height: 0,
    transition: "height 0.3s",
    overflow: "hidden",
    maxHeight: 1000,
    [theme.breakpoints.down("md")]: {
      maxHeight: 10000,
      transition: "height 0s",
      overflow: "visible",
    },
  },
  tagsCover: {
    position: "absolute",
    right: -1,
    top: 0,
    height: "100%",
    width: 40,
    background:
      "linear-gradient(90deg, rgba(241,241,241,0) 0%, rgba(241,241,241,1) 100%)",
    [theme.breakpoints.down("xs")]: {
      width: 16,
    },
  },
}));

const itemsPerPage = 16;
const scroll = Scroll.animateScroll;
const scroller = Scroll.scroller;
const Element = Scroll.Element;

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
    whiteSpace: "nowrap",
  },
  root: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}))(ToggleButtonGroup);

const Home = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [visibleVideos, setVisibleVideos] = useState([]);
  const [visibleOnPageVideos, setVisibleOnPageVideos] = useState([]);
  const videos = useSelector((state) => state.video.videos);
  const tags = useSelector((state) => state.video.tags);
  const activeVideo = useSelector((state) => state.video.activeVideo);
  const lastActiveVideo = useSelector((state) => state.video.lastActiveVideo);
  const activeTags = useSelector((state) => state.video.activeTags);
  const [page, setPage] = useState(1);
  const { xs, sm, md, lg, xl } = useScreensize();
  const [screenWidth] = useWindowSize();
  const visibleOnPageVideosRef = useRef(visibleOnPageVideos);
  visibleOnPageVideosRef.current = visibleOnPageVideos;

  useEffect(() => {
    if (!videos || !activeTags) {
      return;
    }

    console.log(videos);

    setPage(1);

    const videosArr = Object.keys(videos)
      .map((key) => videos[key])
      .filter((video) => {
        if (!video || !video.thumbnails || !video.thumbnails.high) {
          return false;
        }
        return true;
      });

    if (activeTags.indexOf("Alle") !== -1) {
      setVisibleVideos(videosArr);
      return;
    }

    setVisibleVideos(
      videosArr.filter((f) => {
        for (const t of activeTags) {
          if (f.tags && f.tags.indexOf(t) !== -1) {
            return true;
          }
        }
        return false;
      })
    );
  }, [videos, activeTags]);

  useEffect(() => {
    if (!page) {
      return;
    }

    handleActiveVideo(null);

    setVisibleOnPageVideos(
      visibleVideos.slice((page - 1) * itemsPerPage, itemsPerPage * page)
    );

    if (!visibleOnPageVideosRef.current[0]) {
      return;
    }
    setTimeout(() => {
      scroller.scrollTo(`scroll_${visibleOnPageVideosRef.current[0].videoId}`, {
        duration: 300,
        smooth: true,
        offset: xs ? -140 : -200,
      });
    }, 300);
  }, [visibleVideos, page]);

  useEffect(() => {
    if (!activeVideo) {
      return;
    }
    if (xs) {
      scroll.scrollToTop({ duration: 0 });
      return;
    }

    setTimeout(() => {
      scroller.scrollTo(`scroll_${activeVideo.videoId}`, {
        duration: 300,
        smooth: true,
        offset: 150,
      });
    }, 500);
  }, [activeVideo]);

  useEffect(() => {
    if (activeVideo || !lastActiveVideo) {
      return;
    }
    scroller.scrollTo(`scroll_${lastActiveVideo.videoId}`, {
      duration: xs ? 0 : 250,
      smooth: true,
      offset: xs ? -50 : -100,
    });
  }, [activeVideo, lastActiveVideo]);

  const handleSelectedTags = (event, newSelectedTags) => {
    if (
      activeTags.indexOf("Alle") === -1 &&
      newSelectedTags.indexOf("Alle") !== -1
    ) {
      return dispatch(videoActions.setActiveTags(["Alle"]));
    }
    dispatch(
      videoActions.setActiveTags(newSelectedTags.filter((f) => f !== "Alle"))
    );
  };

  const handleActiveVideo = (video) => {
    dispatch(videoActions.setActiveVideo(video));
  };

  const handleChangePage = (e, p) => {
    setPage(p);
  };

  const handleClose = () => {
    dispatch(videoActions.setActiveVideo(null));
  };

  if (!videos) {
    return null;
  }

  const perRow = (xs && 1) || (sm && 2) || (md && 2) || (lg && 3) || 4;

  const detailsHeight =
    (xs && "auto") ||
    (sm && "auto") ||
    (md && "auto") ||
    (lg && screenWidth * 0.5) ||
    screenWidth * 0.4;

  return (
    <>
      <Box
        className={clsx(classes.header, xs && activeVideo && classes.hidden)}
        py={xs ? 4 : 10}
        color="#fff"
      >
        <Container maxWidth="md">
          <Typography variant="h1" align="center" gutterBottom>
            ZAK-Medienseite
          </Typography>
          <Typography variant="body1" align="center">
            Die ZAK steht für eine offensive und transparente
            Öffentlichkeitsarbeit. Als kommunale Anstalt sehen wir es als unsere
            Pflicht an, die Bürgerinnen und Bürger über unser Tun und Wirken zu
            informieren, Entwicklungen darzustellen und gesetzliche Vorgaben
            verständlich zu kommunizieren. Die crossmediale Ausrichtung soll
            dabei helfen, alle Generationen zu erreichen.
          </Typography>
        </Container>
      </Box>
      <Box position="relative">
        <Box
          position="relative"
          display="flex"
          py={2}
          overflow="auto"
          className={clsx(xs && activeVideo && classes.hidden)}
        >
          <Box width={xs ? 16 : 40} flexShrink={0}></Box>
          <StyledToggleButtonGroup
            value={activeTags}
            onChange={handleSelectedTags}
          >
            {tags.map((tag, idx) => (
              <ToggleButton
                value={tag}
                key={idx}
                classes={{ selected: classes.selectedToggleButton }}
              >
                {tag}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          <Box width={xs ? 16 : 40} flexShrink={0}></Box>
        </Box>
        <div className={classes.tagsCover}></div>
      </Box>
      <Box py={0}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={0}>
            {visibleOnPageVideos.map((video, idx) => (
              <React.Fragment key={idx}>
                <Grid
                  component={Element}
                  name={`scroll_${video.videoId}`}
                  item
                  xs={12 / perRow}
                  classes={{ item: xs && activeVideo && classes.hidden }}
                >
                  <Box px={xs ? 0 : 2} py={xs ? 1 : 2}>
                    <Paper
                      elevation={video === activeVideo ? 4 : 0}
                      onClick={() => {
                        handleActiveVideo(video);
                      }}
                      className={classes.paper}
                    >
                      <Box p={!xs || activeVideo !== video ? 2 : 0}>
                        {(!xs || activeVideo !== video) && (
                          <VideoPreview video={video} />
                        )}
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
                {((idx + 1) % perRow === 0 ||
                  idx === visibleOnPageVideos.length - 1) && (
                  <Grid item xs={12}>
                    <div
                      className={clsx(classes.detailWrapper)}
                      style={{
                        height:
                          activeVideo &&
                          visibleOnPageVideos.indexOf(activeVideo) >=
                            idx - perRow + 1 &&
                          visibleOnPageVideos.indexOf(activeVideo) <= idx
                            ? detailsHeight
                            : 0,
                      }}
                    >
                      <Grow
                        in={
                          activeVideo &&
                          visibleOnPageVideos.indexOf(activeVideo) >=
                            idx - perRow + 1 &&
                          visibleOnPageVideos.indexOf(activeVideo) <= idx
                        }
                      >
                        <Box>
                          {activeVideo &&
                            visibleOnPageVideos.indexOf(activeVideo) >=
                              idx - perRow + 1 &&
                            visibleOnPageVideos.indexOf(activeVideo) <= idx && (
                              <Box
                                py={xs ? 0 : 15}
                                px={xs || sm ? 0 : md ? 8 : lg ? 2 : 15}
                              >
                                <VideoDetail
                                  video={activeVideo}
                                  onClose={handleClose}
                                />
                              </Box>
                            )}
                        </Box>
                      </Grow>
                    </div>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <Box
            py={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={clsx(xs && activeVideo && classes.hidden)}
          >
            <Pagination
              count={Math.ceil(visibleVideos.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Home;
