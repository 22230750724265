import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  IconButton,
} from "@material-ui/core";
import YouTube from "react-youtube";
import nl2br from "react-nl2br";
import useScreensize from "../hooks/useScreensize";
import CloseIcon from "@material-ui/icons/Close";
import Agreement from "./Agreement";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  txt: {
    lineHeight: 1.4,
  },
  ytWrapper: {
    boxSizing: "content-box",
    width: "100%",
    height: 0,
    paddingBottom: (9 / 16) * 100 + "%",
    position: "relative",
    background: "#bbb",
  },
  yt: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
  },
  close: {
    marginTop: theme.spacing(-2),
  },
}));

const VideoDetail = ({ video, onClose }) => {
  const agreement = useSelector((state) => state.agreement);
  const classes = useStyles();
  const { xs } = useScreensize();
  if (!video) {
    return null;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={11}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Box mx={xs ? -2 : 0}>
              {agreement && (
                <div className={classes.ytWrapper}>
                  <YouTube
                    videoId={video.videoId}
                    className={classes.yt}
                    opts={{
                      playerVars: {
                        rel: 0,
                      },
                    }}
                  />
                </div>
              )}

              <Agreement />
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box mb={xs ? 3 : 0} pl={{ xs: 0, lg: 4 }} py={{ xs: 4, lg: 0 }}>
              <Typography variant="h4" className={classes.title}>
                {video.title}
              </Typography>
              <Typography
                variant={xs ? "body2" : "body1"}
                className={classes.txt}
              >
                {nl2br(video.description.split(" ").slice(0, 80).join(" "))}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {!xs && (
        <Grid item xs={1} style={{ textAlign: "right" }}>
          <IconButton onClick={onClose} className={classes.close}>
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default VideoDetail;
