import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Container,
  Box,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../hooks/useScreensize";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import * as videoActions from "../store/actions/videoActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  appBar: {
    position: "fixed",
    zIndex: 999,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: 0,
  },
  logo: {
    height: "80%",
    width: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      height: "auto",
    },
  },
  logoWrapper: {
    height: "100%",
    marginRight: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: 212,
      height: "auto",
      background: "#000",
      boxShadow: "0 0 10px rgba(0,0,0,0.5)",
      marginRight: 0,
      justifyContent: "center",
      marginTop: 0,
      borderRadius: theme.shape.borderRadius,
      alignSelf: "flex-start",
      paddingTop: 16,
      paddingBottom: 14,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  appBarMenu: {
    flexGrow: 1,
  },
  tb: {
    height: 1,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  btnBeratung: {
    marginRight: "15px",
  },
  menuImage: {
    backgroundSize: "cover",
  },
  tbAnim: {
    top: 0,
    transition: "top 0.2s",
    position: "relative",
  },
  hideTopBar: {
    [theme.breakpoints.down("xs")]: {
      top: -56,
    },
  },
  closeBtn: {
    position: "absolute",
    right: -60,
    top: 4,
    transition: "right 0.2s",
    color: "#fff",
  },
  showCloseBtn: {
    [theme.breakpoints.down("xs")]: {
      right: 4,
    },
  },
}));

const Layout = ({ children, refs }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const activeVideo = useSelector((state) => state.video.activeVideo);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(videoActions.setActiveVideo(null));
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{ backgroundColor: "#000" }}
        >
          <Toolbar className={classes.tb}>
            <Container maxWidth="xl">
              <Box
                display="flex"
                height={xs ? 56 : 64}
                alignItems="center"
                className={clsx(
                  classes.tbAnim,
                  activeVideo && classes.hideTopBar
                )}
              >
                <Link
                  className={classes.logoWrapper}
                  style={{
                    backgroundColor: xs ? "transparent" : "#000",
                  }}
                  to="/"
                >
                  <img src={Logo} alt="" className={classes.logo} />
                </Link>
                <Box ml="auto">
                  <Button
                    component="a"
                    href="https://www.zak-kl.de/"
                    variant="contained"
                    color="primary"
                    style={{ color: "#fff" }}
                  >
                    zur ZAK-Website
                  </Button>
                </Box>
              </Box>
              <IconButton
                onClick={handleClose}
                className={clsx(
                  classes.closeBtn,
                  activeVideo && classes.showCloseBtn
                )}
              >
                <CloseIcon />
              </IconButton>
            </Container>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
