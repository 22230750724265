const initState = false;

const agreementReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_AGREEMENT":
      return action.enabled;
    default:
      return state;
  }
};

export default agreementReducer;
