const initState = {
  videos: {},
  tags: [],
  activeVideo: null,
  lastActiveVideo: null,
  activeTags: ["Alle"],
};

const videoReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_VIDEOS":
      return {
        ...state,
        videos: action.videos,
      };
    case "SET_TAGS":
      return {
        ...state,
        tags: action.tags,
      };
    case "SET_ACTIVE_VIDEO":
      return {
        ...state,
        activeVideo: action.activeVideo,
        lastActiveVideo: action.lastActiveVideo,
      };
    case "SET_ACTIVE_TAGS":
      return {
        ...state,
        activeTags: action.activeTags,
      };
    default:
      return state;
  }
};

export default videoReducer;
