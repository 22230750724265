import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import useScreensize from "../hooks/useScreensize";

const useStyles = makeStyles((theme) => ({
  thumb: {
    boxSizing: "content-box",
    height: 0,
    paddingBottom: (9 / 16) * 100 + "%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    marginBottom: theme.spacing(1),
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      lineHeight: 1.3,
    },
  },
}));

const VideoPreview = ({ video }) => {
  const classes = useStyles();
  const { xs } = useScreensize();

  if (!video || !video.thumbnails || !video.thumbnails.high) {
    return null;
  }

  return (
    <>
      <div
        className={classes.thumb}
        style={{
          backgroundImage: "url(" + video.thumbnails.high.url + ")",
        }}
      ></div>
      <Typography
        variant={xs ? "h6" : "subtitle2"}
        component="h3"
        className={classes.title}
      >
        {video.title}
      </Typography>
    </>
  );
};

export default VideoPreview;
