import { combineReducers } from "redux";
import agreementReducer from "./agreementReducer";
import videoReducer from "./videoReducer";

const rootReducer = combineReducers({
  agreement: agreementReducer,
  video: videoReducer,
});

export default rootReducer;
