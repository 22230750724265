import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import * as constants from "./constants";
import Home from "./components/pages/Home";
import { CssBaseline } from "@material-ui/core";
import Layout from "./components/Layout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";
import { useDispatch } from "react-redux";
import * as videoActions from "./store/actions/videoActions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(videoActions.getData());
    dispatch(videoActions.getTagData());
  }, []);

  return (
    <BrowserRouter>
      <ScrollMemory />
      <ThemeProvider theme={constants.THEME}>
        <CssBaseline />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
