export const enable = () => (dispatch, getState) => {
  dispatch({
    type: "SET_AGREEMENT",
    enabled: true,
  });
};

export const disable = () => (dispatch, getState) => {
  dispatch({
    type: "SET_AGREEMENT",
    enabled: false,
  });
};
